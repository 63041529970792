// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "x_dN";
export var onlineMarketplaceCardBorderSection = "x_dv";
export var onlineMarketplaceCardRowIconsSection = "x_dB";
export var onlineMarketplaceClientQuotesSection = "x_dP";
export var onlineMarketplaceCycleDevSection = "x_dw";
export var onlineMarketplaceCycleDevSection__testimonial = "x_dx";
export var onlineMarketplaceDomainExpertiseSection = "x_dy";
export var onlineMarketplaceExceptionSection = "x_dz";
export var onlineMarketplaceFeaturedCaseSection = "x_dC";
export var onlineMarketplaceFeaturesSection = "x_dF";
export var onlineMarketplaceFeaturesSection__box = "x_dG";
export var onlineMarketplaceProjLogoSection = "x_dM";
export var onlineMarketplaceRequirementSection = "x_dH";
export var onlineMarketplaceSuccessStoriesSection = "x_dK";
export var onlineMarketplaceSuccessfulSection = "x_dD";
export var onlineMarketplaceTechStackSection = "x_dJ";
export var onlineMarketplaceWhyHireSection = "x_dL";